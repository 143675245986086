<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light mb-4">
    <button
      v-if="can('store', 'promotions')"
      class="btn btn-success mr-auto"
      type="button"
      data-toggle="modal"
      data-target="#modal-promotion-create"
      @click.prevent="$emit('showPromotionCreateForm')"
    >
      <font-awesome-icon icon="plus-circle" /> Create Promotion
    </button>
    <SearchForm
      v-if="can('search', 'promotions')"
      class="ml-auto"
      model="promotion"
      :use-date="true"
      :use-search="true"
      @searchQuery="searchQuery"
    />
  </nav>
</template>

<script>
import search from 'mixins/hasSearch';
import SearchForm from 'components/forms/SearchForm';

export default {
  name: 'PromotionsNavigation',
  mixins: [search],
  components: { SearchForm },
};
</script>
